import './bootstrap';
import '../css/app.postcss';
import 'material-symbols';
import 'material-icons/iconfont/material-icons.css';

/* vuetify */
import 'vuetify/styles';
import 'vue3-toastify/dist/index.css';
import { createVuetify } from 'vuetify';
import * as components from 'vuetify/components';
import * as directives from 'vuetify/directives';
import { createInertiaApp } from '@inertiajs/vue3';
import * as Sentry from '@sentry/vue';
import '@mdi/font/css/materialdesignicons.css'; // Ensure you are using css-loader
import VueShepherdPlugin from 'vue-shepherd';
import { createApp, h } from 'vue';
import { resolvePageComponent } from 'laravel-vite-plugin/inertia-helpers';
import { createPinia } from 'pinia';
import { isAxiosError } from 'axios';
import toast from 'vue-toastification';
import Vue3Toastify from 'vue3-toastify';
import { i18nVue } from 'laravel-vue-i18n';
import { guard } from '@/Redesign/directives/guard';
import { ZiggyVue } from '../../vendor/tightenco/ziggy/dist/vue.m';
import { useSnackbarStore } from './stores/snackbar';
import { VueReCaptcha } from 'vue-recaptcha-v3'

import { loadFonts } from './plugins/webfontloader';
import 'vue-toastification/dist/index.css';
import "driver.js/dist/driver.css";

const pinia = createPinia();
const vuetify = createVuetify({
  icons: {
    defaultSet: 'mdi',
  },
  display: {
    // it doest not change css breakpoints, only $vuetify.display and useDisplay() composable
    mobileBreakpoint: 'sm',
    thresholds: {
      xs: 0,
      sm: 640, // Tailwind's sm
      md: 768, // Tailwind's md
      lg: 1024, // Tailwind's lg
      xl: 1280, // Tailwind's xl
      '2xl': 1536, // Tailwind's 2xl
    },
  },
  components,
  directives,
  treeshake: true,
});

loadFonts(); // does not work

const appName = window.document.getElementsByTagName('title')[0]?.innerText || 'Laravel';

createInertiaApp({
  title: (title) => `${title} - ${appName}`,
  resolve: (name) => {
    const redesignPages = import.meta.glob('./Redesign/Pages/**/*.vue');
    const pages = import.meta.glob('./Pages/**/*.vue');

    const allPages = { ...redesignPages, ...pages };

    return resolvePageComponent(`./Redesign/Pages/${name}.vue`, allPages).catch(() => resolvePageComponent(`./Pages/${name}.vue`, allPages));
  },
  setup({
    el, App, props, plugin,
  }) {
    const app = createApp({ render: () => h(App, props) })
      .use(plugin)
      .use(ZiggyVue, Ziggy)
      .use(vuetify)
      .use(VueShepherdPlugin)
      .use(pinia)
      .use(toast)
      .use(VueReCaptcha, { siteKey: import.meta.env.VITE_GOOGLE_RECAPTCHA_SITE_KEY })
      .use(Vue3Toastify, {
        bodyClassName: 'font-primary text-sm',
        autoClose: 10000,
      })
      .use(i18nVue, {
        lang: 'en',
        resolve: async (lang = 'en') => {
          const langs = import.meta.glob('../../lang/*.json');
          return langs[`../../lang/${lang}.json`]();
        },
        fallbackLang: 'en',
      });

    if (import.meta.env.MODE !== 'localhost') {
      Sentry.init({
        app,
        dsn: 'https://e2ec9838180c2d9f58c6b8c67893202b@o4507463646707712.ingest.de.sentry.io/4507463653982288',
        environment: import.meta.env.MODE,
        tracesSampleRate: 1.0,
        trackComponents: true,
        logErrors: true,
        integrations: [
          Sentry.replayIntegration({
            maskAllText: false,
            blockAllMedia: false,
          }),
        ],
        replaysSessionSampleRate: 0.1,
        replaysOnErrorSampleRate: 1.0,
      });
    }

    app.config.errorHandler = (error, instance, info) => {
      const snackbar = useSnackbarStore();

      if (isAxiosError(error)) {
        const message = error.response.data.message || error.message;
        snackbar.show(message);
      }

      if (import.meta.env.MODE !== 'localhost') {
        Sentry.captureException(error);
      }

      console.error(error);
    };

    app.directive('guard', guard);
    app.mount(el);
  },
  progress: {
    color: '#4B5563',
  },
});
