import debugFactory from 'debug';
import { IS_DEV_ENVIRONMENT } from './constants';

export const shouldEnableDebugging = import.meta.env.VITE_DEBUG === 'true';
if (shouldEnableDebugging) {
	const namespaces = import.meta.env.VITE_DEBUG_NAMESPACES.split(',');
	namespaces.forEach((namespace) => namespaces.push(`${namespace}:warn`, `${namespace}:error`));

	debugFactory.enable(namespaces.join(','));
}

const debuggerPerNamespace = {};

export function debug(namespace, ...logs) {
	if (!debuggerPerNamespace[namespace]) {
		debuggerPerNamespace[namespace] = debugFactory(namespace);

		const logType = namespace.split(':').pop();
		debuggerPerNamespace[namespace].log = getLoggerFunctionByLogType(logType).bind(console);
	}

	debuggerPerNamespace[namespace](logs.shift(), ...logs);
}

export function devDebug(namespace, ...logs) {
	if (IS_DEV_ENVIRONMENT) {
		debug(namespace, ...logs);
	}
}

function getLoggerFunctionByLogType(logType) {
	switch (logType) {
		case 'warn':
			return console.warn;
		case 'error':
			return console.error;
		default:
			return console.log;
	}
}
