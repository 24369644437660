import useUser from '@/Redesign/composables/useUser';

const { user, getMyUserInfo } = useUser();

const hasPermission = async (permissionKey) => {
  if (!user.value) {
    await getMyUserInfo();
  }
  
  return user.value?.scopes?.includes(permissionKey);
};

const guard = {
  beforeMount: async (el, binding) => {
    const permission = binding.value;
    const hasPerm = await hasPermission(permission);

    if (!hasPerm) {
      const comment = document.createComment('Permission denied');
      el.replaceWith(comment);
      await binding.instance.$nextTick();
    }
  },
};

export { hasPermission, guard };
